<template>
  <SlModal
    :id="id"
    :title="title"
    persistent
    @created="onCreated"
    @on-enter="handleApply"
    @hide="onModalHide"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content forecast-versions-form"
    >
      <SlValidate
        v-if="!isFinalForecast && !isStatisticalForecast"
        v-slot="{ invalid }"
        vid="name"
        mode="eager"
        :rules="nameRules"
      >
        <SlInput
          v-model="name"
          :label="$t('Web.Table.Header.Name')"
          :is-invalid="invalid"
          required
        />
      </SlValidate>
      <SlSelect
        v-model="fieldType"
        select-label="Type"
        :options="forecastVersionTypeOptions"
        :disabled="isEditMode"
      />
      <SlControl
        v-model="viewType"
        :options="permissionOptions"
        :label="$t('Web.CustomFields.Viewers')"
      />
      <transition-expand>
        <div v-if="viewType === forecastVersionAccessTypes.SOME">
          <SlSelect
            v-model="viewers"
            :options="specificUsersOptions"
            multiple
            allow-empty
            clearable
            :close-on-select="false"
            :placeholder="$t('Web.CustomFields.InputPlaceholder')"
            group-values="options"
            group-label="group"
            track-by="id"
            label="name"
          >
            <template #selectOption="{ option, selected }">
              <template v-if="option.$groupLabel">
                <p class="forecast-versions__select-header">
                  {{ option.$groupLabel }}
                </p>
              </template>
              <template v-else-if="option.name">
                <div class="forecast-versions__select-option">
                  <img
                    v-if="option.profilePictureUrl"
                    referrerpolicy="no-referrer"
                    class="forecast-versions__select-image"
                    :src="option.profilePictureUrl"
                  >
                  <icon
                    v-else
                    data="@icons/roles_users.svg"
                    class="fill-off size-20 forecast-versions__select-image"
                  />
                  {{ option.name }}
                </div>
              </template>
              <icon
                v-if="selected"
                data="@icons/check.svg"
                class="fill-off size-16 color-primary-80 check-icon"
              />
            </template>
            <template #tag="{ option, remove }">
              <div class="forecast-versions__select-tag">
                <img
                  v-if="option.profilePictureUrl"
                  referrerpolicy="no-referrer"
                  class="forecast-versions__select-image forecast-versions__select-image--small"
                  :src="option.profilePictureUrl"
                >
                <icon
                  v-else
                  data="@icons/roles_users.svg"
                  class="fill-off size-18 forecast-versions__select-image"
                />
                <div class="forecast-versions__select-tag-text">
                  {{ option.name }}
                </div>
                <icon
                  data="@icons/close.svg"
                  class="fill-off size-18 ml-4"
                  @click="remove(option)"
                />
              </div>
            </template>
          </SlSelect>
        </div>
      </transition-expand>
      <SlControl
        v-if="!isStatisticalForecast"
        v-model="editType"
        :options="permissionOptions"
        :label="$t('Web.CustomFields.Editors')"
      />
      <transition-expand>
        <div v-if="editType === forecastVersionAccessTypes.SOME">
          <SlSelect
            v-model="editors"
            :options="specificUsersOptions"
            multiple
            allow-empty
            clearable
            :close-on-select="false"
            :placeholder="$t('Web.CustomFields.InputPlaceholder')"
            group-values="options"
            group-label="group"
            track-by="name"
            label="name"
          >
            <template #selectOption="{ option, selected }">
              <template v-if="option.$groupLabel">
                <p class="forecast-versions__select-header">
                  {{ option.$groupLabel }}
                </p>
              </template>
              <template v-else-if="option.name">
                <div class="forecast-versions__select-option">
                  <img
                    v-if="option.profilePictureUrl"
                    referrerpolicy="no-referrer"
                    class="forecast-versions__select-image"
                    :src="option.profilePictureUrl"
                  >
                  <icon
                    v-else
                    data="@icons/roles_users.svg"
                    class="fill-off size-20 forecast-versions__select-image"
                  />
                  {{ option.name }}
                </div>
              </template>
              <icon
                v-if="selected"
                data="@icons/check.svg"
                class="fill-off size-16 color-primary-80 check-icon"
              />
            </template>
            <template #tag="{ option, remove }">
              <div class="forecast-versions__select-tag">
                <img
                  v-if="option.profilePictureUrl"
                  referrerpolicy="no-referrer"
                  class="forecast-versions__select-image forecast-versions__select-image--small"
                  :src="option.profilePictureUrl"
                >
                <icon
                  v-else
                  data="@icons/roles_users.svg"
                  class="fill-off size-18 forecast-versions__select-image"
                />
                <div class="forecast-versions__select-tag-text">
                  {{ option.name }}
                </div>
                <icon
                  data="@icons/close.svg"
                  class="fill-off size-18 ml-4"
                  @click="remove(option)"
                />
              </div>
            </template>
          </SlSelect>
        </div>
      </transition-expand>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="hideModal(id)"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleApply">
          {{ primaryButtonText }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import {
  forecastVersionAccessTypes,
  forecastVersionTypeOptions
} from '@/config/demand/table.config';
import { mapState } from 'vuex';

export default {
  name: 'ForecastVersionsFormModal',
  mixins: [modal],
  data() {
    return {
      modalsId,
      isEditMode: false,
      forecastVersionAccessTypes,
      forecastVersionTypeOptions,
      id: modalsId.FORECAST_VERSIONS_FORM,
      applyCallback: null,
      isStatisticalForecast: false,
      isFinalForecast: false,
      takenForecastNames: [],
      primaryButtonText: '',
      title: '',
      name: '',
      initialName: '',
      fieldType: '',
      editType: '',
      viewType: '',
      editors: [],
      viewers: []
    };
  },
  computed: {
    ...mapState({
      users: state => state.userAccess.users,
      roles: state => state.userAccess.roles
    }),
    nameRules() {
      return {
        required: true,
        max: 50,
        sl_not_includes: {
          values: this.takenForecastNames.filter(name => name !== this.initialName),
          message: this.$t('Web.Error.AlreadyExist', {
            1: this.$t('Web.Error.Names.ForecastVersion')
          })
        }
      };
    },
    permissionOptions() {
      return [
        {
          label: this.$t('Web.CustomFields.pAll'),
          value: forecastVersionAccessTypes.ALL
        },
        {
          label: this.$t('Web.CustomFields.pSpecific'),
          value: forecastVersionAccessTypes.SOME
        }
      ];
    },
    specificUsersOptions() {
      return [
        {
          group: this.$t('WiMultiuserTab.Ui.rolesBtn'),
          options: this.roles?.rows?.map(role => ({
            name: role.Name.val,
            id: role.id
          }))
        },
        {
          group: this.$t('WiMultiuserTab.Ui.usersBtn'),
          options: this.users?.rows?.map(user => ({
            name: user.Name.val,
            profilePictureUrl: user.Name.profilePictureUrl,
            id: user.id
          }))
        }
      ];
    }
  },
  methods: {
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key] !== undefined) {
          this[key] = props[key];
        }
      });

      this.initialName = this.name;
    },
    onModalHide() {
      this.isEditMode = false;
      this.applyCallback = null;
      this.primaryButtonText = '';
      this.isStatisticalForecast = false;
      this.isFinalForecast = false;
      this.takenForecastNames = [];
      this.title = '';
      this.name = '';
      this.initialName = '';
      this.fieldType = '';
      this.editType = '';
      this.viewType = '';
      this.editors = [];
      this.viewers = [];
    },
    async handleApply() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      this.applyCallback && await this.applyCallback({
        name: this.name.trim(),
        type: this.fieldType,
        editType: this.editType,
        edit: this.editors,
        viewType: this.viewType,
        view: this.viewers
      });

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/forecast-versions/form-modal.scss";
</style>
