<template>
  <SlModal
    :id="id"
    :title="title"
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content forecast-versions-info">
      <template v-if="roles.length">
        <div class="info__title body-4-md">
          {{ $t('WiMultiuserTab.Ui.rolesBtn') }}
        </div>
        <div class="info__list">
          <div
            v-for="role in roles"
            :key="role.id"
            class="info__item"
          >
            <icon
              data="@icons/roles_users.svg"
              class="fill-off size-32 info__icon"
            />
            <div class="info__item-name">
              {{ role.Name.val }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="users.length">
        <div class="info__title body-4-md">
          {{ $t('WiMultiuserTab.Ui.usersBtn') }}
        </div>
        <div class="info__list">
          <div
            v-for="user in users"
            :key="user.id"
            class="info__item"
          >
            <img
              :src="user.Name.profilePictureUrl"
              referrerpolicy="no-referrer"
              class="info__image"
            >
            <div class="info__item-name">
              {{ user.Name.val }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ForecastVersionsInfoModal',
  mixins: [modal],
  data() {
    return {
      modalsId,
      id: modalsId.FORECAST_VERSIONS_INFO,
      title: '',
      roles: [],
      users: []
    };
  },
  methods: {
    onModalHide() {
      this.title = '';
      this.roles = [];
      this.users = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/forecast-versions/info-modal.scss";
</style>
